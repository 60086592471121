@import "../../../_variables";

.custom-MUI-button {

  &.MuiButton-root {
    background-color: $blue;
    padding: 7.5px 10px;
    &:hover {
      background-color: $blue;
    }
  }

  @media (max-width: $breakpoint) {
  }
}
