@import '../../../../../__variables.scss';

.purchase-profile-pipeline-extended-criteria {
  .section {
    .title {
      font-family: 'Stolzl-Bold';
    }
    .input-groups {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      .wrapper {
        width: 30%;
        margin: 20px 0px;
        .group {
          margin-top: 15px;
          &.large {
            width: 70%;
          }

          .label {
            margin-bottom: 10px;
          }
          .subgroup {
            display: flex;
            gap: 10px;
            .element {
              flex: 1 1 0px;
            }
          }
          .values {
            .tag-list {
              margin-bottom: 10px;

              &.bottom {
                margin-top: 10px;
                margin-bottom: 0px;
              }

              display: flex;
              gap: 5px;
              flex-wrap: wrap;
              .tag {
                border-radius: 30px;
                background-color: $blue;
                align-items: center;
                padding: 5px;
                display: inline-block;

                .btnRemove {
                  display: inline-block;
                  cursor: pointer;
                  * {
                    color: $white;
                  }
                }

                .value {
                  display: inline-block;
                  margin-left: 5px;
                  margin-right: 5px;
                  color: $white;
                }
              }
            }
            .value-input {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
