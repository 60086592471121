@import '../../../../__variables.scss';

.new-object-pipeline-component-navigation {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.new-object-bulletpoint-navigation {
  margin-top: 10vh;
  .timeline {
    margin-left: 7px;
    .box {
      width: 100%;
      display: flex;

      .lines {
        margin-top: 0px;
        .dot {
          width: 14px;
          height: 14px;
          margin-left: -10.5px;
          margin-top: -4.5px;
          svg * {
            color: $lightblue;
          }
          &.active {
            svg * {
              color: $blue;
            }
          }
        }

        .line {
          height: 10vh;
          width: 2px;
          background: $lightblue;
          &.active {
            background: $blue;
          }
        }
      }
      .items {
        margin-left: 20px;
        margin-top: -2px;
        .item {
          height: calc(10vh + 8px);
          color: $lightblue;
          &.active {
            font-family: 'Stolzl-Bold';
            color: $blue;
          }
        }
      }
    }
  }
}
