@import "../../../__variables.scss";

#header {
  &.user-scrolled {
    box-shadow: 5px 5px 23px -13px $black;
  }
  
  background-color: $bg;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 1000;
  height: 80px;
  padding: 15px;

  .logo {
    cursor: pointer;
    font-weight: bolder;
    color: $white;
    img {
      max-height: 40px;
    }
  }

  .desktop {
    display: flex;
    align-items: center;
    height: 100%;
    .logo,
    .search,
    .item {
      flex: 1 1 0px;
    }
    .search input {
      font-size: $font-size-menu;
      border: none;
      padding: 3px 10px;
      &:active,
      &:focus {
        border: none;
      }
    }
    .item.selected {
      color: $blue;
    }
  }

  .mobile {
    display: none;
  }

  @media (max-width: $breakpoint) {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
}
