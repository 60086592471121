@import '../../../../../../__variables.scss';

#autocomplete {
  .predictionList {
    margin-top: 10px;
    .predictionItem {
      padding: 10px;
      margin-top: 10px;
      border-radius: 5px;
      background-color: $blue;
      color: $white;
      cursor: pointer;
    }
  }
}
