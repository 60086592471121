@import '../../../../__variables.scss';

.storage-upload-modal {
  #file-input {
    display: none;
  }
  .upload-label,
  .upload-cta {
    padding: 5px;
    display: block;
    border-radius: $default-border-radius;
    background-color: $blue;
    padding: 7.5px 10px;
    color: $white;
    width: 100%;
    text-align: center;
    cursor: pointer;
  }
  .file-list {
    .file {
      margin-top: 10px;
      &:last-child {
        margin-bottom: 10px;
      }
      display: flex;
      gap: 5px;
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 65%;
      }
      .size {
        width: 25%;
      }
      .remove-icon {
        width: 10%;
        text-align: right;
        color: $red;
        cursor: pointer;
      }
    }
  }
}
