@import "../../../../__variables.scss";

.translation-item {
  border-top: thin solid $blue;
  &:first-child {
    border-top: none;
  }
  padding: 5px 0px;
  margin: 5px 0px;
  .key {
    font-weight: bold;
  }

  .property {
    margin: 10px 0px;
  }
  .update {
    background-color: $gray;
    text-align: center;
    cursor: pointer;
    padding: 5px;
  }
  .message {
    text-align: center;
    font-size: $text-small;
  }

  @media (max-width: $breakpoint) {
  }
}
