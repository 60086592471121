@import '../../../_variables';

.custom-MUI-textarea {
  &.MuiTextField-root {
    .MuiFilledInput-root:after,
    .MuiInput-root:after {
      border-color: $blue;
    }
    .MuiInputLabel-shrink {
      color: $blue;
    }
  }

  @media (max-width: $breakpoint) {
  }
}
