@import "../../../../__variables.scss";

.section {

  .address-street-element-wrapper {
    display: flex;
    gap: 0px;
    margin-bottom: 15px;
    .element {
      width: 15%;
      border-left: thin solid $gray;
      &:first-child {
        width: 60%;
        border: none;
      }
      &:last-child {
        width: 25%;
      }
    }
  }
  
}
