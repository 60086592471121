@import "../../../../__variables.scss";

.settings-item {
  border-top: thin solid $blue;
  display: flex;
  flex-wrap: wrap;
  &:first-child {
      border-top: none;
  }
  padding: 5px 0px;
  margin: 5px 0px;
  .key {
    font-weight: bold;
    width: 100%;
  }

  .application, .property {
      width: 25%;
  }
  .update {
      width: 100%;
      background-color: $gray;
      text-align: center;
      cursor: pointer;
      margin-top: 10px;
      padding: 5px;
  }
  .message {
      text-align: center;
      width: 100%;
      font-size: $text-small;
  }

  @media (max-width: $breakpoint) {
  }
}
