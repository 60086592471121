@import '../../../__variables.scss';

#purchase-profile {
  .new-purchase-profile-link-wrapper {
    .new-purchase-profile {
      position: absolute;
      bottom: 40px;
      display: flex;
      align-items: center;
      .button {
        padding: 2px;
        background-color: $blue;
        border-radius: 40px;
        margin-right: 15px;
        svg {
          font-size: 50px;
          * {
            color: $white;
          }
        }
      }
      .text {
        color: $blue;
        font-family: 'Stolzl-Bold';
      }
    }
  }
}
