@import "../../../../_variables";

.chat-room {
  background-color: $bg;
  border-radius: $default-border-radius;
  box-shadow: $default-box-shadow;
  padding: 15px;
  #chat-messages-wrapper {
    height: 40vh;
    overflow: scroll;
    margin-bottom: 15px;
  }
  .compose {
    display: flex;
    gap: 15px;
    .input {
      width: 95%;
      display: flex;
      background-color: $white;
      align-items: center;
      border-radius: 50px;
      #attachment-input {
        display: none;
      }
      textarea {
        resize: none;
        border: none;
        background-color: transparent;
        width: 90%;
        outline: none;
        padding-left: 10px;
      }
      .attachment-wrapper {
        width: 10%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          font-size: 35px;
          * {
            color: $black;
          }
        }
      }
    }
    .submit {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 55px;
      height: 55px;
      background-color: $blue;
      border-radius: 50px;
      cursor: pointer;
      svg {
        font-size: 35px;
        * {
          color: $white;
        }
      }
    }
  }
  .attachment-list {
    .headline {
      margin-top: 10px;
      font-family: "Stolzl-Bold";
    }
    .item {
      padding: 5px 0px;
      display: flex;
      .name {
        width: 70%;
      }
      .size {
        width: 20%;
      }
      .remove {
        width: 10%;
        cursor: pointer;
        svg * {
          color: $red;
        }
      }
    }
  }
}
