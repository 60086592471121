@import '../../../../../__variables.scss';

.purchase-profile-pipeline-asset-class {
  .section {
    .title {
      font-family: 'Stolzl-Bold';
    }
    .input-groups {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      .wrapper {
        width: calc(33% - 9px);
        margin: 20px 0px;
        .group {
          margin-top: 15px;
          &.large {
            width: 70%;
          }
          .label {
            margin-bottom: 10px;
          }
          .subgroup {
            display: flex;
            gap: 10px;
            .element {
              flex: 1 1 0px;
            }
          }
        }
      }
    }
  }
}
