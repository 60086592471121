@import "../../../_variables";

#chat {
  .split {
    display: flex;
    gap: 20px;
    .room-list-wrapper {
      width: 30%;
    }
    .room-wrapper {
      width: 70%;
    }
  }
}
