@import "../../../__variables.scss";

#admin-geo-data {
  .import-export {
    display: flex;
    padding: 15px 0px;
    margin: 15px 0px;
    border-top: thin solid $blue;
    border-bottom: thin solid $blue;
    gap: 10px;
    flex-wrap: wrap;
    .import-csv-wrapper {
      .upload-input {
        display: none;
      }
      flex: 1 1 0px;
      label {
        background-color: $gray;
        padding: 5px;
        text-align: center;
        cursor: pointer;
        display: block;
      }
      .trigger-upload {
        display: block;
        background-color: $blue;
        color: $white;
        cursor: pointer;
        text-align: center;
        padding: 5px;
        margin-top: 5px;
      }
    }

    .explain {
      width: 100%;
      .label {
        font-family: "Stolzl-Bold";
      }
    }
  }

  .list-headline {
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: $text-large;
  }

  @media (max-width: $breakpoint) {
  }
}
