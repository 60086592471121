@import '../../../__variables.scss';

#dashboard-layout {
  display: flex;
  #sidebar {
    width: 20vw;
    height: 100vh;
    background-color: $white;
    padding: 40px 30px;
    position: relative;
    .logo {
      img {
        max-width: 100%;
        max-height: 40px;
      }
    }
    .navigation {
      margin-top: 20%;
      .title {
        font-size: $text-large;
        font-family: 'Stolzl-Bold';
        border-bottom: thin solid $gray;
        margin-bottom: 10px;
      }
      .item a {
        color: $black;
        padding: 5px;
        display: block;
      }
    }
    .profile {
      position: absolute;
      bottom: 0px;
      left: 0px;
      display: flex;
      width: 100%;
      padding: 40px 30px;
      align-items: center;
      gap: 10px;
      .image {
        width: 15%;
      }
      .name-logout {
        width: calc(85% - 10px);
        .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          a {
            color: $black;
          }
        }
        .logout-wrapper {
          .logout {
            cursor: pointer;
            color: $red;
            font-size: small;
          }
        }
      }
    }
  }
  #dashboard-content-wrapper {
    width: 80vw;
    min-height: 100vh;
    background-color: $bg;
    position: relative;
    padding: 40px;
    #dashboard-content {
      min-height: 100%;
    }
    &.card {
      max-height: 100vh;
      overflow: hidden;
      #dashboard-content {
        max-height: 100%;
        min-height: auto;
        border-radius: $default-border-radius;
        box-shadow: $default-box-shadow;
        background-color: $white;
      }
    }
  }
}
