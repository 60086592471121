@import '../../../../../../__variables.scss';

.locationCard {
  width: 30%;
  padding: 10px;
  margin: 10px;
  background-color: #f0f5f5;
  border-radius: $default-border-radius;
  box-shadow: $default-box-shadow;

  .locationTitle {
    color: $black;
    font-size: $text-medium;
    font-weight: bolder;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .locationSubTitle {
    color: $black;
    font-size: $text-medium;
  }

  .locationInputNextTo {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    gap: 20px;
  }

  .itemList {
    margin-top: 10px;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    .locationListItem {
      border-radius: 30px;
      background-color: $blue;
      align-items: center;
      padding: 5px;
      display: inline-block;

      .btnRemove {
        display: inline-block;
        cursor: pointer;
        * {
          color: $white;
        }
      }

      .value {
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
        color: $white;
      }
    }
  }
  // .locationListItem {
  //   padding: 10px;
  //   border-radius: $default-border-radius;
  //   background-color: #fff;
  //   display: flex;
  //   align-items: center;
  // }
}
