@import '../../../../__variables.scss';

.purchase-profile-list {
}

.modalRequestRemove {
  .btnWrapper {
    margin-top: 10px;
    display: flex;

    gap: 10px;
    .colorRed {
      &.MuiButton-root {
        background-color: $red;
        &:hover {
          background-color: $red;
        }
      }
    }
  }
}
