@import '../../../../../__variables.scss';

.new-objects-suggestions-item {
  margin-bottom: 15px;
  border-radius: $default-border-radius;
  box-shadow: $default-box-shadow;
  background-color: $white;
  padding: 15px;
  .header {
    display: flex;
    margin-bottom: 15px;
    .title {
      width: 70%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: $text-x-large;
      color: $blue;
      font-family: 'Stolzl-Bold';
    }
  }
}
