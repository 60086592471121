@import '../../../__variables.scss';

#qAndA {
  .qAndAHeader {
    display: flex;
    align-items: center;

    .search {
      width: 40%;
      background-color: #f2f5f5;
      border-radius: 50px;
      box-shadow: $default-box-shadow;
      padding: 20px;

      .searchIcon {
        margin-right: 10px;
        * {
          color: $blue;
        }
      }

      .searchInput {
        border: none;
        background-color: transparent;
        &:focus {
          outline: none;
        }
      }
    }

    .buttonWrapper {
      width: 60%;

      text-align: right;

      button {
        width: 40%;
      }
    }
  }

  .questionList {
    .entry {
      margin-top: 15px;
      padding-bottom: 5px;
      border-bottom: 2px solid $gray;
      &:last-child {
        border-bottom: none;
      }
      .question {
        display: flex;
        gap: 10px;
        .iconShow {
          * {
            color: $blue;
          }
          cursor: pointer;
        }
        .questionText {
          color: $blue;
        }
      }
      .answer {
        margin-left: 30px;
        color: #606060;
      }
    }
  }
}

.modalCreateQuestion {
  .modalCreateQuestionBody {
    div {
      margin-bottom: 10px;
    }
    .btnWrapper {
      margin-top: 10px;
      display: flex;

      gap: 10px;
      .colorRed {
        &.MuiButton-root {
          background-color: $red;
          &:hover {
            background-color: $red;
          }
        }
      }
    }
  }
}
