@import "../../../../../_variables";

.chat-room-message {
  margin-bottom: 15px;
  display: flex;
  max-width: 60%;
  flex-shrink: 0;
  flex-wrap: wrap;
  .image-and-time {
    width: 10%;
    text-align: center;
    .time {
      font-size: $text-small;
    }
  }

  .content {
    max-width: calc(90% - 10px);
    margin-right: 10px;
    border-radius: $default-border-radius;
    box-shadow: $default-box-shadow;
    background-color: $white;
    align-items: center;
    display: flex;
    height: fit-content;
    word-break: break-word;
    white-space: break-spaces;
    flex-wrap: wrap;
    overflow: hidden;
    .text,
    .attachments {
      width: 100%;
      padding: 15px;
      margin: 0px;
    }
    .attachments {
      background-color: $white;
    }
  }
  &.own {
    flex-direction: row-reverse;
    margin-left: auto;
    .content {
      border-top-right-radius: 0px;
      background-color: $blue;
      .text {
        color: $white;
      }
    }
  }
  &.partner {
    .content {
      border-top-left-radius: 0px;
      margin-right: 0px;
      margin-left: 10px;
    }
  }
  .attachments {
    width: 100%;
    background-color: $blue;
    color: $white;
    border-top: thin solid $blue;
    a {
      .item {
        border-bottom: thin solid $lightblue;
      }
      &:last-child {
        .item {
          border-bottom: none;
        }
      }
    }
    .item {
      display: flex;
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70%;
      }
      .size {
        text-align: right;
        width: 30%;
      }
    }
  }
}
