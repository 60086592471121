@import '../../../../../__variables.scss';

.purchase-profile-card {
  margin-bottom: 15px;
  border-radius: $default-border-radius;
  box-shadow: $default-box-shadow;
  background-color: $white;
  padding: 15px;
  .header {
    display: flex;
    margin-bottom: 15px;
    .title {
      width: 70%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: $text-x-large;
      color: $blue;
      font-family: 'Stolzl-Bold';
    }
    .controls {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: right;
      .control {
        cursor: pointer;
        margin: 0px 3px;
        background-color: $white;
        border-radius: 40px;
        height: 30px;
        width: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: thin solid $blue;
        svg {
          font-size: 18px;
          * {
            color: $blue;
          }
        }
        &:hover {
          background-color: $blue;
          svg {
            * {
              color: $white;
            }
          }
        }
      }
    }
  }

  .body {
    display: flex;
    .column {
      width: 33%;
      padding-right: 25px;
      margin-right: 25px;
      border-right: 2px solid $gray;
      display: flex;
      gap: 25px;
      flex-wrap: wrap;
      &:last-child {
        border: none;
        margin-right: 0px;
        padding-right: 0px;
      }
      .prop {
        width: calc(50% - 13px);
        &.locations {
          width: 100%;
          .list {
            .item {
              margin-top: 5px;
              padding: 3px;
              border: thin solid $gray;
              border-radius: $default-border-radius;
            }
          }
        }
        .key {
          font-family: 'Stolzl-Bold';
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
