@import '../../../__variables.scss';

#settings {
  .section {
    &:first-child {
      margin-bottom: 20px;
    }
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    .column {
      width: calc(30% - 10px);
      &.profile-image {
        width: 10%;
      }
      .responsibilities {
        .title {
          font-family: 'Stolzl-Bold';
        }
      }
    }
    .double-element-wrapper {
      display: flex;
      gap: 10px;
      .element {
        flex: 1 1 0px;
      }
    }
    .element-wrapper,
    .double-element-wrapper {
      margin-bottom: 15px;
    }
    .headline {
      width: 100%;
      font-family: 'Stolzl-Bold';
    }
  }
  .footerContent {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
