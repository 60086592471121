@import '../../../../../../_variables';

.custom-MUI-suggestion {
  .btnSelectAll {
    cursor: pointer;
  }
  .float {
    .selected-suggestions {
      margin: 5px 0px;
      border-radius: $default-border-radius;
      box-shadow: $default-box-shadow;
      .title {
        padding: 5px;
      }
      .item {
        padding: 5px;
        display: flex;
        gap: 5px;
        border-bottom: thin solid $gray;
        &:last-child {
          border-bottom: none;
        }
        .suggestion-label {
          width: 95%;
          margin-bottom: 0px;
        }
        .suggestion-remove {
          width: 5%;
          cursor: pointer;
        }
      }
    }

    .suggestions {
      margin: 5px 0px;
      border-radius: $default-border-radius;
      box-shadow: $default-box-shadow;
      overflow: hidden;
      .title {
        padding: 5px;
      }
      .item-wrapper {
        max-height: 200px;
        overflow: scroll;
        .item {
          padding: 5px;
          border-bottom: thin solid $gray;
          cursor: pointer;
          &:last-child {
            border-bottom: none;
          }
          &:hover {
            background-color: $blue;
            color: $white;
          }
        }
      }
    }
  }

  @media (max-width: $breakpoint) {
  }
}
