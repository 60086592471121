@import "../../../_variables";

.custom-link {
  color: $blue;
  text-decoration: none;
  font-size: $text-medium;
  &:hover {
      color: $blue;
  }
}
