@import "../../_variables";

.universal-image-wrapper-round {
  position: relative;
  background-size: contain;
  width: 100%;
  height: auto;
  padding-top: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
}

.universal-image-wrapper {
  width: 100%;
  position: relative;
  img {
    max-width: 100%;
  }
}

.universal-image-wrapper,
.universal-image-wrapper-round {
  .upload-handler {
    top: 0px;
    left: 0px;
    position: absolute;
    height: 100%;
    width: 100%;
    .upload-input {
      display: none;
    }
    .upload-input-label {
      background-color: $gray;
      transition: all 0.3s;
      opacity: 0;
      cursor: pointer;
      height: 100%;
      width: 100%;
      color: $black;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        opacity: 1;
      }
    }
    .processing {
      background-color: $gray;
      height: 100%;
      width: 100%;
      color: $black;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
