@import '../../../__variables.scss';

#storage {
  .breadcrumbs {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: thin solid $gray;
    div {
      display: inline-block;
    }
    .crumb,
    .arrow {
      padding: 5px;
    }
    .crumb {
      cursor: pointer;
      &:hover {
        background-color: $lightblue;
        border-radius: $default-border-radius;
        color: $white;
      }
    }
  }
  .list {
    .item {
      padding: 5px;
      border-bottom: thin solid $gray;
      cursor: pointer;
      &:hover {
        background-color: $lightblue;
        color: $white;
      }
    }
  }
}
