@import "../../__variables.scss";

#layout {
    .navigation-wrapper {
        .toggle {
            position: absolute;
            right: 15px;
            top: 15px;
            z-index: 11;
            img {
                height: 60px;
                width: 60px;
                cursor: pointer;
            }
        }
    }
    #content-container {
        min-height: 100vh;
        padding-top: 80px;
        background-color: $bg;
    }
    &.fullsize {
        #content-container {
            padding-top: 0px;
        }
    }
}