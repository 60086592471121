@import '../../../../__variables.scss';

.my-objects-active {
  .cardItem {
    padding: 10px;
    border-radius: $default-border-radius;
    background-color: $white;
    box-shadow: $default-box-shadow;
    cursor: pointer;
  }
}
