@import "../../../../_variables";

.chat-room-list {
  background-color: $bg;
  box-shadow: $default-box-shadow;
  border-radius: $default-border-radius;
  overflow: hidden;
  .room {
    cursor: pointer;
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 10px;
    border-bottom: thin solid $gray;
    &.active {
      background-color: $white;
    }
    &:last-child {
      border-bottom: none;
    }
    .image {
      width: 20%;
    }
    .name-and-message-wrapper {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      .name,
      .message {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .name {
        width: 75%;
      }
      .time {
        width: 25%;
        text-align: right;
      }
      .message {
        width: 75%;
        font-size: $text-small;
      }
      .unseen-messages {
        width: 25%;
        text-align: right;
        .bubble {
          text-align: center;
          background-color: $blue;
          color: $white;
          display: inline-block;
          padding: 3px;
          border-radius: 25px;
          width: 20px;
          height: 20px;
          line-height: 15px;
          font-size: $text-small;
        }
      }
    }
  }
}
