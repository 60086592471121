@import "../../../../__variables.scss";

#dashboard-layout-fullscreen-header {
  display: flex;
  align-items: center;
  .headline,
  .controls {
    width: 50%;
  }
  .controls {
    text-align: right;
    .control {
      margin: 0px 5px;
      display: inline-block;
      .icon-wrapper {
        background-color: $lightblue;
        border-radius: 30px;
        display: flex;
        width: 35px;
        height: 35px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .MuiSvgIcon-root {
          font-size: 20px;
          line-height: 20px;
          * {
            color: $white;
          }
        }
      }
    }
  }

  .headline {
    font-size: $text-x-large;
    color: $blue;
    font-family: "Stolzl-Bold";
  }
  .hint {
    font-size: $text-small;
  }
}
