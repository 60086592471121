@import "../../../__variables.scss";

#admin-translations {
  .lang-selector {
    border-bottom: thin solid $gray;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .create-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .application-selection {
      width: 100%;
      .selection .option {
        display: inline-block;
        margin-right: 10px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    .property {
      width: calc(50% - 5px);
      margin-bottom: 5px;
      input {
        width: 100%;
      }
    }
    .create {
      width: 100%;
      text-align: center;
      background-color: $blue;
      padding: 5px;
      cursor: pointer;
    }
    .error {
      text-align: center;
      width: 100%;
      padding: 5px;
      font-size: $text-small;
    }
  }

  .import-export {
    display: flex;
    padding: 15px 0px;
    margin: 15px 0px;
    border-top: thin solid $blue;
    border-bottom: thin solid $blue;
    gap: 10px;
    .export-wrapper,
    .import-wrapper {
      flex: 1 1 0px;
      background-color: $gray;
      padding: 5px;
      text-align: center;
      cursor: pointer;
    }
    .export-wrapper {
      border-right: thin solid $gray;
    }
    .export-text,
    .import-text,
    .import-translations-from-file-text {
      position: fixed;
      top: 10vh;
      width: 90vw;
      height: 60vh;
      left: 5vw;
      z-index: 2;
      textarea {
        width: 100%;
        height: 100%;
      }
      input {
        background-color: $white;
      }
      .controls {
        display: flex;
        gap: 10px;
        .close,
        .process {
          flex: 1 1 0px;
          cursor: pointer;
          background-color: $blue;
          text-align: center;
        }
        .process {
          border-right: thin solid gray;
        }
      }
    }
  }

  .list-headline {
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: $text-large;
  }

  @media (max-width: $breakpoint) {
  }
}
