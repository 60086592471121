@import "../../../../__variables.scss";

#dashboard-layout-card-header {
  padding: 30px;
  border-bottom: 2px solid $gray;

  .headline {
      font-size: $text-x-large;
      color: $blue;
      font-family: 'Stolzl-Bold';
  }
  .hint {
    font-size: $text-small;
  }
}
