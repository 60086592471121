@import '../../../../__variables.scss';

.storage-file {
  display: flex;
  padding: 5px 0px;
  margin: 5px 0px;
  border-bottom: thin solid $gray;
  gap: 10px;
  &:last-child {
    border-bottom: none;
  }
  .name {
    width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .size {
    width: 10%;
  }
  .remove-wrapper,
  .download-wrapper {
    width: 15%;
    text-align: right;
  }
  .remove {
    cursor: pointer;
  }
}
